// import logo from './logo.svg';
import downarrow from './images/Arrows_down_animated.gif';
import './App.css';
import Content from './content/Content';
function App() {
    function down(int){
        // window.scrollTo(0, window.innerHeight);
        window.scroll({ top: window.innerHeight, behavior: 'smooth' });

    }

  return (
    <div className="App">
        <header className="App-header">
            <h1>Elizabeth Chen<span></span></h1>
            <p>front-end developer</p>
          <div className="content">
            <a href="mailto:me@elizabethchen.com">Email</a>
            &nbsp;|&nbsp;
            <a href="//www.linkedin.com/in/elizabethchen0/">LinkedIn</a>
          </div>
            <div className="down" onClick={down}>
                <img src={downarrow} alt="down"/>
            </div>
        </header>
        <Content></Content>
    </div>
  );
}

export default App;
