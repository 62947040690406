import React, { Component } from 'react';
import downarrow from '../images/Arrows_down_animated.gif';
import profile from '../images/profile.jpg';

class Content extends Component {
    constructor(props) {
        super(props);
        // self = this;
        // this.handler = this.handler.bind(this);
        this.state = {
        };
    }


    componentWillUnmount(){}

    componentDidMount(){}

    downinner(int, context, event){
        console.log('con', event, int, window.innerHeight);
        window.scroll({ top: window.innerHeight*int, behavior: 'smooth' });

    }
    render(props) {

        // this.down = function(int){
        //     console.log('con', int, window.innerHeight);
        //     // window.scroll({ top: window.innerHeight*int, behavior: 'smooth' });
        //
        // }

        return (
            <div>
                <div id="content1" className="work section">
                    <h2>Work</h2>
                    <div className="maxwidth">
                        <div>
                            <a href="https://drive.google.com/file/d/1VjmtcC8pEIHpFmkVPUjN60_1JGdwJduA/view?usp=sharing">Resume</a>
                        </div>
                    </div>
                    <p>coming soon</p>
                    <div className="down" onClick={this.downinner.bind(this, 2)}>
                        <img src={downarrow} alt="down"/>
                    </div>
                </div>
                <div id="content2" className="about section">
                    <h2>About Me</h2>
                    <div className="maxwidth">
                        <div className="flexcon">
                            <div>
                                <img src="//elizabethchen.com/2022/wp-content/uploads/2022/03/liz-2-smaller-678x1024.jpg" alt="me"/>
                            </div>
                            <div>
                                <p>
                                    Front-end developer with expertise in HTML, CSS, and Javascript. I have an acute understanding of melding aesthetics and code, stemming from a design education and years of experience in the web industry. Verticals include wellness, shopping comparison, retail, and marketing design. Proven track record for delivering high quality products in fast paced environments.
                                </p>
                                <p>Originally from Houston, Texas but now calls Brooklyn, NY home.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Content; // Don’t forget to use export default!